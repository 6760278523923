import React from "react";
import {Tab, Tabs, InputGroup} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import Link from "next/link";
import {useRouter} from "next/router";
import clsx from "clsx";

import {relativeSecurityInspectorUrlFor} from "@finance-apps/common/data/url";

import styles from "./Nav.module.scss";

// =================================================================================================

function Nav() {
    const router = useRouter();
    // console.log(router);

    const isDebugMode = typeof router.query.debug !== "undefined";

    const selectedTabId = router.asPath.startsWith("/s/") ?
        "/stock-inspector-discover-new-companies-etfs" :
        router.asPath;

    const handleSubmit = function (event) {
        // console.log(event);
        event.preventDefault();
        const symbol = event.target.search.value?.trim().toUpperCase();
        if (symbol) {
            return router.push(relativeSecurityInspectorUrlFor(symbol));
        }
    }
    return (
        <nav className={styles.nav}>
            <div className="nav-content">
                <form onSubmit={handleSubmit}>
                    {/* Uncontrolled usage with defaultValue because it doesn't handle onChange,
                        the parent form handles onSubmit */}
                    <InputGroup
                        placeholder="Stock & ETF symbol..."
                        type="search"
                        className={clsx(styles["symbol-input"], "symbol-input")}
                        leftIcon={IconNames.SEARCH}
                        name="search"
                        defaultValue={router.query.symbol}
                    />
                </form>
                <Tabs
                    id="tabs"
                    selectedTabId={selectedTabId}
                    vertical={true}
                >
                    <Tab id="/stock-inspector-discover-new-companies-etfs">
                        <Link href="/stock-inspector-discover-new-companies-etfs">Stock Inspector</Link>
                    </Tab>
                    <Tab id="/unicorn-ipo-leaderboard">
                        <Link href="/unicorn-ipo-leaderboard">Unicorn IPO Leaderboard</Link>
                    </Tab>
                    <Tab id="/ipo-windows">
                        <Link href="/ipo-windows">IPO Windows</Link>
                    </Tab>
                    <Tab id="/recessions-bear-markets-compared">
                        <Link href="/recessions-bear-markets-compared">Recessions Compared</Link>
                    </Tab>
                    {/*<Tab id="/startup-equity-calculator">*/}
                    {/*    <Link href="/startup-equity-calculator">Startup Equity Calculator</Link>*/}
                    {/*</Tab>*/}
                    {isDebugMode &&
                        <Tab id="/recession-trading-strategy" title="Recession Trading Strategy" />}
                </Tabs>
            </div>
        </nav>
    );
}

export default Nav;
