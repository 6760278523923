import React from "react";
import Link from "next/link";
import clsx from "clsx";

import styles from "./Footer.module.scss";

function Footer({className}) {
    return (
        <footer className={clsx(styles["app-footer"], className)}>
            <div className={styles["links"]}>
                <a href="https://shan.io">© 2020 All rights reserved.</a>
                <div>
                    <a href="https://status.shan.io/">System status</a>
                    <span> • </span>
                    <Link href="/privacy-policy">Privacy policy</Link>
                </div>
            </div>
            <div className={styles["disclosures"]}>
                <p>
                    <strong>Important disclosures</strong>: all investments involve risks, including
                    the possible loss of capital.

                    Past performance does not guarantee or indicate future results. Please consider
                    your investment objectives, risk tolerance and pricing before investing.

                    Diversification and asset allocation do not guarantee a profit, nor do they
                    eliminate the risk of loss of principal.
                </p>
                <p>
                    Any hypothetical performance shown is for illustrative purposes only. Such
                    results do not represent actual results and do not take into consideration
                    economic or market factors which can impact
                    performance. You may achieve investment results materially different
                    from the results portrayed.

                    A properly suggested portfolio recommendation is dependent upon
                    current and accurate financial and risk profiles. If you experienced
                    changes to your goals, financial circumstances or investment objectives, or
                    wish to modify your portfolio recommendation, you should promptly update your
                    information through the website.

                    Third Party Quotes shown may not be representative of the experience of Acorns
                    customers and do not represent a guarantee of future performance or success.
                    Please click on each testimonial to review the context from which this quote was
                    taken.
                </p>
                <p>
                    This web experience, Stock Inspector and associated applications are operated by
                    A Future Secured LLC. Market prices, data and other information are not
                    warranted as to completeness or accuracy and are subject to change without
                    notice. System response, execution price, speed, liquidity, market
                    data, and account access times are affected by many factors, including market
                    volatility, size and type of order, market conditions, system performance, and
                    other factors.
                </p>
                <p>
                    A Future Secured LLC is currently registered in the jurisdiction of the United
                    States of America. This
                    is not an offer, solicitation of an offer, or advice to buy or sell securities,
                    or open a brokerage account in any jurisdiction where A Future Secured LLC is
                    not registered.
                    A Future Secured LLC does not offer securities trading. It is not a
                    member of FINRA / SIPC, which protects securities customers
                    of its members up to $500,000 (including $250,000 for claims for cash).
                    Explanatory brochure available upon request or at www.sipc.org.
                </p>
                <p>
                </p>
            </div>
        </footer>
    );
}

export default Footer;
