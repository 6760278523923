import React from "react";

import {withRouter} from "next/router";
import Head from "next/head";

import {a} from "@finance-apps/common/analytics";
import {init} from "errorMonitoring";
init();

import Nav from "Nav/Nav";
import Footer from "Footer/Footer";
import ActivityIndicator from "@finance-apps/common/ActivityIndicator/ActivityIndicator";

// Remove blue accessibility outline unless user is tabbing through the UI
import {FocusStyleManager} from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

import "styles/globals.scss";

// =================================================================================================

class App extends React.Component {
    state = {isLoading: false};

    // Called only on initial render
    componentDidMount() {
        // Not cancelling listeners in componentWillUnmount because App is always mounted
        this.props.router.events.on("routeChangeStart", this.handleRouteChangeStart.bind(this));
        this.props.router.events.on("routeChangeComplete", () => this.setState({isLoading: false}));
        this.props.router.events.on("routeChangeError", this.setState({isLoading: false}));
        this.trackPageview(this.props.router.asPath);
        // console.log("initial render");
    }

    handleRouteChangeStart(url) {
        // this.props.router.asPath is the previous URL, this function's argument is the next URL.
        if (this.props.router.asPath !== url) {
            // console.log(this.props.router.asPath, url);
            this.setState({isLoading: true});
            this.trackPageview(url);
        }
    }

    trackPageview(url) {
        // console.log(url);
        // Do not set document location:
        // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications#do_not_update_the_document_location
        a.set("page", url);
        a.send("pageview", url);
    }

    get loadingState() {
        return (
            <div className="content loading-state">
                <ActivityIndicator />
            </div>
        );
    }

    render() {
        let {Component, pageProps, error} = this.props;

        return (
            <>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, viewport-fit=cover"
                    />
                </Head>
                <div id="App">
                    <Nav />
                    {this.state.isLoading ?
                        this.loadingState : <Component {...pageProps} err={error} />}
                    <Footer className="app-footer" />
                </div>
            </>
        );
    }
}

export default withRouter(App);
